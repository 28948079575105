// ::ng-deep and other piercing selectors are deprecated by Angular.
//
// For styling material components, we need to use global styles with an
// appropriate container class (in this case, `sp-mat-tab-group`)

.sp-mat-tab-group {
	.mat-tab-header {
		background-color: $gray100;
		margin-bottom: 12px;
		margin-left: 0;
		margin-right: 0;
		border-radius: 8px;
		border-bottom: 0px;
		top: 0;
		position: sticky;
		z-index: 10;
	}

	.mat-tab-labels {
		display: flex;
		gap: 0;
		height: 40px;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		padding: 2px 0;
	}

	.mat-tab-list {
		padding: 0;
	}

	.mat-tab-label-content {
		flex-direction: column;
	}

	.mat-tab-label {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		box-sizing: border-box;
		height: auto;
		font-size: 16px;
		min-width: 1px;
		padding: 0;
		color: $gray500;
		font-weight: 400;
		opacity: 1;
		font-style: normal;
		line-height: normal;
		margin: 0 2px;
		.tab-label-wrapper {
			box-sizing: border-box;
			padding: 15px 14px;
			display: flex;
			gap: 4px;
		}
		cursor: pointer;
	}

	.mat-tab-label-active {
		background-color: #fff;
		height: 40px;
		padding: 0;
		color: $navy500;
		box-shadow: 0px 2px 4px 0px rgba(16, 20, 24, 0.08);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin: 0 2px;
		cursor: pointer;
	}

	.mat-tab-body-wrapper,
	.mat-tab-body-content,
	.mat-tab-body.mat-tab-body-active {
		overflow: visible !important;
	}

	.mat-ripple:not(:empty) {
		transform: none;
	}
	.mat-tab-header-pagination {
		display: none;
	}
	.mat-ink-bar {
		display: none !important;
	}
}
