/* You can add global styles to this file, and also import other style files */

@import 'custom-theme.scss';
@import 'picker.min.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons&style=outline');
@import '~font-awesome/css/font-awesome.min.css';
@import 'design-system';

/* angular-cli file: src/styles.css */

@import '../node_modules/angular-calendar/css/angular-calendar.css';

html {
	background-color: #fcfdff;
	padding: 0 0;
	right: 0;
	left: 0;
}

body {
	position: absolute;
	height: 100vh;
	padding: 0 0;
	margin: 0 0;
	font-family: $primaryFont;
	width: 100%;
	// IOS PWA styles
	-webkit-overflow-scrolling: touch;
	-webkit-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	input,
	textarea,
	select > option {
		font-family: inherit;
	}
}

// translation uses "var" tag to isolate dynamic parts of a phrase
var {
	font-style: inherit;
}

.filter-shadow {
	filter: url('#dropshadow');
}

button {
	user-select: none;
}

img {
	user-drag: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	user-select: none;
}

div {
	//cursor: default;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Chrome/Safari/Opera */
	-khtml-user-select: none;
	/* Konqueror */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                     not supported by any browser */
}

@import 'styles/page';
@import 'styles/modal';
@import 'styles/pass-limits';
@import 'styles/content_tabs';

/* Render material container invisibly for Backpack,
   as we provide our own container component internally. */
.bp-dialog-container .mat-dialog-container {
	padding: 0;
	box-shadow: none;
	background: transparent;
	border-radius: 0;
	overflow: visible;
}

.mat-dialog-container {
	.sp-dialog {
		&.wrapper {
			background-color: #ffffff;
			width: 425px;
			height: 100%;
		}

		.header {
			height: 70px;
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;

			.background-header {
				border-top-left-radius: 14px;
				border-top-right-radius: 14px;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
			}

			.back-button {
				width: 28px;
				cursor: pointer;
				position: absolute;
				font-size: 15px;
				font-weight: 500;
				color: #1f195e;
				left: 15px;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding: 20px;
			height: 390px;
			overflow: auto;

			.using {
				padding: 20px;

				.title {
					font-size: 15px;
					color: $navy;
					margin-bottom: 12px;
					font-weight: bold;
					margin-left: 10px;
				}

				.header {
					height: 70px;
					display: flex;
					position: relative;
					align-items: center;
					justify-content: center;

					.background-header {
						border-top-left-radius: 14px;
						border-top-right-radius: 14px;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
					}

					.back-button {
						width: 28px;
						cursor: pointer;
						position: absolute;
						font-size: 15px;
						font-weight: 500;
						color: #1f195e;
						left: 15px;
					}
				}
			}
		}
	}
}

.nux-container .mat-dialog-container {
	padding: 19px;
	border-radius: 12px;
	width: 509px;
}

.accent-background-section {
	background-color: $gray050;
	border-radius: 8px;
	padding: 15px;
	margin-top: 20px;
}

.support-icon {
	width: 17px;
	height: 17px;
	margin-left: 7px;
	cursor: help;
}

.dynamic-dialog-modal .mat-dialog-container,
.dynamic-dialog-modal-min .mat-dialog-container {
	box-shadow: 0px 5px 26px 0px #00000026;
	width: 540px;
	// min-height: 289px;
	max-height: 85vh;
	border-radius: 12px;
	padding: 0;
	overflow: visible;
}

.dynamic-dialog-modal-additional-terms .mat-dialog-container {
	box-shadow: 0px 5px 26px 0px #00000026;
	width: 540px;
	// min-height: 289px;
	max-height: 75vh;
	border-radius: 12px;
	padding: 0;
	overflow: visible;
}

.dynamic-dialog-modal.wide .mat-dialog-container {
	width: 580px;
}
.dynamic-dialog-modal.wider .mat-dialog-container {
	width: 640px;
}

.dynamic-dialog-modal.fit-content-width .mat-dialog-container {
	min-width: 540px;
	width: fit-content;
}

.dynamic-dialog-modal.has-scroll .mat-dialog-container {
	overflow: hidden;
}

.dynamic-dialog-modal-min .mat-dialog-container {
	min-height: fit-content;
}

.set-schedule-dynamic-dialog-modal .mat-dialog-container {
	width: 600px;
	box-shadow: 0px 5px 26px 0px #00000026;
	min-height: 289px;
	max-height: 85vh;
	border-radius: 12px;
	padding: 0;
	overflow: visible;
}

.form-dialog-container .mat-dialog-container {
	border-radius: 14px;
	overflow: hidden;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 0;
	scrollbar-face-color: #d6d6d6;
	scrollbar-track-color: white;
	background-color: transparent;
	z-index: 100;
}

.form-dialog-container-white .mat-dialog-container {
	border-radius: 14px;
	overflow: hidden;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 0;
	scrollbar-face-color: #d6d6d6;
	scrollbar-track-color: white;
	background-color: white;
}

.class-details-modal .mat-dialog-container {
	border-radius: 14px;
	overflow: hidden;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	width: 580px;
	border-radius: 14px;
	max-height: 836px;
	padding: 0;
	background-color: $gray050;
}

.admin-form-dialog-container-white .mat-dialog-container {
	border-radius: 14px;
	overflow: hidden;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	padding: 0;
	scrollbar-face-color: #d6d6d6;
	scrollbar-track-color: white;
	background-color: white;
}

.sp-form-dialog .mat-dialog-container {
	border-radius: 14px;
	overflow: hidden;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 0;
	scrollbar-face-color: #d6d6d6;
	scrollbar-track-color: white;
	background-color: transparent;
	z-index: 100;
	width: 425px;
	height: 500px;
}

.main-form-dialog-container .mat-dialog-container {
	border-radius: 14px;
	background-color: transparent;
	overflow: visible;
	box-shadow: none;
	margin: 0;
	padding: 0;
	scrollbar-face-color: #d6d6d6;
	scrollbar-track-color: white;
}

.main-form-dialog-container-mobile .mat-dialog-container {
	margin: 0;
	padding: 0;
}

.pass-card-dialog-container .mat-dialog-container {
	border-radius: 10px;
	overflow: visible;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 0;
	width: 262px;
	height: 366px;
}

.teacher-pass-card-dialog-container .mat-dialog-container {
	overflow: visible;
	box-shadow: none;
	margin: 0;
	padding: 0;
	background-color: transparent;
	width: 100%;
}

.student-pass-card-dialog-container .mat-dialog-container {
	overflow: visible;
	box-shadow: none;
	margin: 0;
	padding: 0;
	background: transparent;
}

.search-pass-card-dialog-container .mat-dialog-container {
	border-radius: 14px;
	overflow: visible;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 0;
}

.accounts-profiles-dialog .mat-dialog-container {
	border-radius: 14px;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	padding: 0;
}

@media all and (max-width: 1110px) {
}

.overlay-dialog .mat-dialog-container,
.student-search-dialog .mat-dialog-container {
	border-radius: 16px;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	z-index: 10;
	padding: 0;
}

.student-search-dialog .mat-dialog-container {
	overflow: visible;
}

.overlay-dialog.show-overlay .mat-dialog-container {
	overflow: auto !important;
}

.overlay-dialog-no-background {
	@extend .overlay-dialog;

	.mat-dialog-container {
		border-radius: 16px;
		box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
		overflow: hidden;
		z-index: 10;
		padding: 0;
		background: none !important;
	}
}

.student-pass-info-dialog .mat-dialog-container {
	border-radius: 10px;
	box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.15);
	width: 1007px;
	height: 564px;
	overflow: hidden;
	z-index: 10;
	padding: 0;
}

.editor-dialog {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 2px 26px 0px rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 10px;
	background-color: rgba(1, 25, 52, 0.7);
}

.id-card-overlay-container {
	max-width: 100vw !important;
}

.checkin-room-code-dialog-container {
	.mat-dialog-container {
		padding: 0 !important;
	}
}

.error-toast-dialog-container .mat-dialog-container {
	width: auto;
	height: auto;
	padding: 0;
	background: transparent;
}

.message-dialog-container .mat-dialog-container {
	@extend .editor-dialog;
	width: 300px;
	height: 145px;
}

.date-dialog-container .mat-dialog-container {
	@extend .editor-dialog;
	width: 319px;
	height: 511px;
}

.location-dialog-container .mat-dialog-container {
	@extend .editor-dialog;
	width: 300px;
	height: 209px;
}

.consent-dialog-container .mat-dialog-container {
	@extend .editor-dialog;
	overflow: visible;
	padding: 0;
	background-color: transparent;
	//text-align: center;
}

.consent-dialog-container-helpcenter {
	z-index: 1009;

	.mat-dialog-container {
		@extend .editor-dialog;
		overflow: visible;
		padding: 0;
		background-color: transparent;
		z-index: 1009;
	}
}

.menu-dialog-container .mat-dialog-container {
	overflow: visible;
	padding: 0;
	background-color: transparent;
	border-radius: 14px;
	box-shadow: 0px 1.3px 2.7px 0px rgba(16, 20, 24, 0.08);
}

.select-days-flex .mat-dialog-container {
	border-radius: 8px;
	border: 0.7px solid $gray100;
	background: white;
	box-shadow: 0px 1.3px 2.7px 0px rgba(16, 20, 24, 0.08);
	padding: 0;
	overflow-y: hidden;
}

.toast .mat-dialog-container {
	padding: 0;
	border-radius: 9px;
}

.calendar-dialog-container .mat-dialog-container {
	padding: 0;
	overflow: visible;
	box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.15);
	border-radius: 14px;
}

.flex-activities .mat-dialog-container {
	padding: 0;
	overflow: scroll;
	box-shadow: 0px 16px 48px 0px rgba(16, 20, 24, 0.18);
	border-radius: 16px;
}

.add-school-popup-container .mat-dialog-container {
	padding: 0;
	border-radius: 8px;
}

input:focus {
	outline-offset: 0;
	outline: none;
	transition: border 0.2s ease;

	&:focus {
		border: 1px solid #595487;
	}
}

textarea:focus {
	outline-offset: 0;
	outline: none;
	transition: border 0.2s ease;
}

.custom-backdrop {
	background-color: rgba(16, 20, 24, 0.4);
}

.lighter-black-backdrop {
	background: rgba(0, 0, 0, 0.6);
}

.invis-backdrop {
	background-color: rgba(0, 0, 0, 0);
}

.white-backdrop {
	background: rgba(255, 255, 255, 0.5);
}

.pwa-backdrop {
	background-color: rgba(0, 0, 0, 0.1);
}

.notification-backdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000 !important;
	pointer-events: auto;
	-webkit-tap-highlight-color: transparent;
	background: rgba(0, 0, 0, 0.32);
	-webkit-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
	transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.custom-bd {
	@extend .custom-backdrop;
}

html {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.root-router-child {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

[routerLink],
[routerLink]:focus,
[routerLinkActive] {
	outline: none;
}

.global-opacity-icons {
	transition: opacity 0.3s ease;

	&:hover {
		opacity: 0.8;
	}
}

.teacher-view div.collection-content.collection-flex {
	gap: 12px !important;
}

//////////////// Dragula Styles /////////////////

/* in-flight clone */

.gu-mirror {
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 0.8;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
	filter: alpha(opacity=80);
	pointer-events: none;
}

/* high-performance display:none; helper */

.gu-hide {
	left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */

.gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

/* added to the source element while its mirror is dragged */

.gu-transit {
	opacity: 0;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
	filter: alpha(opacity=0);
}

//////////////// End Dragula Styles /////////////////

/* === Material Overrides === */

body {
	.mat-list-item {
		font-family: 'Helvetica Neue', sans-serif;
	}

	.mat-drawer-backdrop {
		z-index: 99;
	}

	.mat-table {
		font-family: $primaryFont;
	}
}

@media all and (max-height: 375px) {
	.cdk-overlay-pane.teacher-pass-card-dialog-container,
	.cdk-overlay-pane.student-pass-card-dialog-container {
		height: 98%;
	}
	.teacher-pass-card-dialog-container .mat-dialog-container,
	.student-pass-card-dialog-container .mat-dialog-container {
		overflow-y: auto;
		overflow-x: hidden;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

@media all and (max-width: 320px) {
	.teacher-pass-card-dialog-container .mat-dialog-container,
	.student-pass-card-dialog-container .mat-dialog-container {
		width: 100% !important;
	}
	.form-dialog-container {
		max-width: 300px !important;
	}
}

@media all and (max-width: 475px) {
	.student-pass-card-dialog-container,
	.teacher-pass-card-dialog-container {
		max-width: unset !important;
	}
}

#rooms-list .mat-list-item.heading .mat-list-item-content {
	cursor: default;
	color: $gray500;
	font-weight: 500;
	font-size: 14px;
}

#rooms-list .mat-list-item.list-item .mat-list-item-content:hover {
	padding: 0;
	margin-left: 8px;
	margin-right: 8px;
	background: $gray050;
	border-radius: 8px;

	.room {
		padding-left: 8px;
		padding-right: 8px;
	}
}

// Side nav scroll hiding
.mat-drawer-inner-container::-webkit-scrollbar {
	width: 0 !important;
}

.mat-drawer-inner-container {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
}

/* === end === */

/* === Swiper Overrides === */

.swiper-slide {
	margin: 10px 0;
}

.cdk-overlay-container {
	z-index: 1005;
}

/* === Hubspot Overrides === */

@media screen and (max-width: 420px) {
	div#hubspot-messages-iframe-container {
		display: none !important;
	}
}

//// queries for safari
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		@media all and (max-width: 1024px) {
			html {
				overflow: auto;
				-webkit-overflow-scrolling: touch;
			}
			body {
				height: auto;
				overflow: visible;

				.passes-wrapper {
					height: auto !important;
				}

				.hall-monitor-wrapper {
					height: auto !important;
					overflow: visible !important;

					.hall-monitor-bottom {
						margin: 0 !important;
					}
				}
			}
			.global-container {
				height: auto !important;
				overflow: visible !important;
			}
			app-main-page {
				height: auto !important;
				overflow: visible !important;
			}
			mat-sidenav-container,
			mat-sidenav-content {
				min-height: 100vh;
				height: auto !important;
				overflow: visible !important;
			}
		}
	}
}

//  /* Here goes the CSS rules that will only apply if app is running standalone */
@media all and (prefers-color-scheme: light) {
}

.mat-jade {
	color: white !important;
	background-color: $jade !important;
}

.underline {
	text-decoration: underline;
}

.referral-dialog-container .mat-dialog-container {
	padding: 19px 19px 54px;
	border-radius: 12px;
	width: 509px;
	box-shadow: 0px 16px 48px rgba(16, 20, 24, 0.18);
}

.insights-dialog-container .mat-dialog-container {
	display: flex;
	width: 509px;
	height: fit-content;
	padding: 60px 60px 0 60px;
}

.no-fly-time-tooltip-dialog-container .mat-dialog-container {
	border-radius: 20px;
	background: var(--gray-white, #fff);
	padding: 0px;
}

.app-group-settings-container .mat-dialog-container {
	height: 581px;
	width: 802px;
	padding: 0px;
	border-radius: 13px;
	border: 1px solid #d4d9e2;
	background: #f5f6f8;
	box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.06);
	overflow: visible;
}

@media print {
	.mat-dialog-container {
		box-shadow: none !important;
		border-radius: 0 !important;
	}
	.top-bar-container {
		display: none;
	}
}

@media all and (max-width: 420px) {
	/* this is a hacky workaround to counter act the transform scale on mobile width: 120% !important; */
	.student-pass-card-dialog-container,
	.teacher-pass-card-dialog-container {
		width: 96% !important;
	}
}
