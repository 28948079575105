@import 'styles/shared';

.page-wrapper {
	min-height: calc(100vh - 75px); // Full height minus navigation bar
	box-sizing: border-box;
	background: $gray050;
	padding-top: 36px;
	padding-bottom: 40px;

	@media screen and (max-width: 1350px) {
		// Account for 74px bottom navigation bar at smaller screen sizes
		padding-bottom: 94px;
	}
}

.page-content {
	box-sizing: border-box;
	max-width: 1230px;
	padding: 0 40px;
	margin-left: auto;
	margin-right: auto;
}

.page-header {
	margin-bottom: 18px;
}

.page-header-title-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.page-header-title {
	font-size: 24px;
	color: $navy500;
	font-weight: 700;
}
